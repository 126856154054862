import {
  Button,
  Card,
  Checkbox,
  Flex,
  Paper,
  PasswordInput,
  TextInput,
} from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  json,
  redirect,
} from "@remix-run/node";
import { useFetcher } from "@remix-run/react";
import { IconAlertCircle, IconLock, IconUser } from "@tabler/icons-react";
import { useEffect } from "react";
import { AuthenticityTokenInput } from "remix-utils/csrf/react";
import { z } from "zod";
import {
  commitAuthSession,
  destroyAuthSession,
  getAuthSession,
} from "~/utils/auth.cookie";
import { actionHandler } from "./action";

const schema = z.object({
  username: z.string().min(1, "Wajib diisi"),
  password: z.string().min(1, "Wajib diisi"),
});

export async function action({ request }: ActionFunctionArgs) {
  return actionHandler(request);
}

export async function loader({ request }: LoaderFunctionArgs) {
  const session = await getAuthSession(request.headers.get("Cookie"));
  const isLogout = request.url.includes("?logout");
  if (isLogout) {
    return redirect("/", {
      headers: {
        "Set-Cookie": await destroyAuthSession(session),
      },
    });
  }
  if (session.has("auth-token")) {
    return redirect("/app", {
      headers: {
        "Set-Cookie": await commitAuthSession(session),
      },
    });
  }
  return json(
    {},
    {
      headers: {
        "Set-Cookie": await commitAuthSession(session),
      },
    }
  );
}

export default function Auth() {
  const form = useForm<z.infer<typeof schema>>({
    validate: zodResolver(schema),
    initialValues: {
      password: "",
      username: "",
    },
  });
  const fetcher = useFetcher<typeof action>();

  useEffect(() => {
    if (fetcher.data) {
      if (fetcher.data) {
        showNotification({
          title: "Login gagal",
          message: fetcher.data.message,
          icon: <IconAlertCircle />,
          color: "orange",
        });
      }
    }
  }, [fetcher.data]);

  return (
    <div className="w-screen h-screen bg-[url('/login-background.avif')]">
      <Paper className="absolute h-screen w-screen lg:w-1/2 flex items-center justify-center">
        <Card className="w-full max-w-sm px-4">
          <form
            onSubmit={form.onSubmit((_v, e) => {
              if (e) {
                fetcher.submit(e.currentTarget, {
                  method: "post",
                  action: "/?index",
                });
              }
            })}
            className="space-y-4"
          >
            <Flex justify="center">
              <img src="tmhub-logo.svg" alt="TMHub Trustmedis" />
            </Flex>
            <br />
            <h4 className="sr-only">Masuk akun Anda</h4>
            <TextInput
              leftSection={<IconUser size={16} />}
              name="username"
              label="Username"
              autoFocus
              required
              className="w-full"
              my="lg"
              {...form.getInputProps("username")}
            />
            <AuthenticityTokenInput
              name="csrf"
              {...form.getInputProps("csrf")}
            />
            <PasswordInput
              leftSection={<IconLock size={16} />}
              name="password"
              label="Password"
              className="w-full"
              required
              my="lg"
              {...form.getInputProps("password")}
            />
            <Checkbox my="lg" label="Remember me" />
            <Button
              loading={fetcher.state !== "idle"}
              type="submit"
              className="w-full bg-trustmedis"
            >
              Login
            </Button>
          </form>
        </Card>
      </Paper>
    </div>
  );
}
